import React, { useEffect, useState } from 'react';
import { styled, Typography } from '@mui/material';
import { FlexCol, FlexRow } from 'components/atoms/Flex';
import { Gutter } from 'components/atoms/Gutter';
import QRCode from 'react-qr-code';
import { getUserBalance } from 'api/auth';
import { IUser } from 'typings';
import { AvailabeExchange, exchanges } from 'data/exchanges';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index-reducer';

const Wrapper = styled(FlexCol)`
	padding: 16px;
	min-width: 300px;
	//	border: 1px solid #235ee1;
	border-radius: 8px;
`;

const ItemWrapper = styled(FlexRow)`
	flex-wrap: wrap;
`;

const Item = styled(FlexRow)<{ isSeleted: boolean }>`
	align-items: center;
	padding: 10px 15px;
	background-color: ${({ isSeleted }) =>
		isSeleted ? 'rgb(26, 26, 51)' : 'rgb(12,12,34)'};
	border: 1px solid ${({ isSeleted }) => (isSeleted ? '#fff' : '#235ee1')};
	border-radius: 6px;
	box-shadow: 0 4px 4px #000000;
	margin: 5px;
	overflow: hidden;

	:hover {
		cursor: pointer;
		background-color: rgb(34, 34, 64);
	}
`;

export const DepositPanel = () => {
	const [balance, setBalance] = useState({ balance: '', symbol: '' });
	const user: IUser = useSelector(
		(state: RootState) => state.user.user,
	) as IUser;
	const [exchange, setExchange] = useState<AvailabeExchange>('AVAX');

	const fetchBalance = async () => {
		try {
			const res = await getUserBalance(user.address, exchange);
			setBalance(res);
		} catch (e) {}
	};

	useEffect(() => {
		fetchBalance();
	}, [exchange]);

	return (
		<Wrapper>
			<ItemWrapper>
				{exchanges.map((d) => (
					<Item
						key={d.name}
						isSeleted={exchange === d.name}
						onClick={() => setExchange(d.name)}
					>
						<Typography
							variant={'body1'}
							color={exchange === d.name ? '#fff' : '#aaa'}
							fontWeight={600}
						>
							{d.name}
						</Typography>
					</Item>
				))}
			</ItemWrapper>
			<Gutter spacing={1} />
			<QRCode
				value={user.address}
				style={{ width: '100%', height: 200 }}
			/>
			<Gutter spacing={2} />
			<Typography>{user.address}</Typography>
			<Gutter spacing={0.5} />
			<FlexRow style={{ width: '100%' }} justify={'space-between'}>
				<Typography variant={'body2'} fontWeight={600}>
					Balance
				</Typography>
				<Typography
					variant={'body2'}
					fontWeight={600}
				>{`${balance.balance} ${balance.symbol}`}</Typography>
			</FlexRow>
		</Wrapper>
	);
};
