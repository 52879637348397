import React, { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FlexCol, FlexRow } from 'components/atoms/Flex';
import { Gutter } from 'components/atoms/Gutter';
import { Exchanges } from 'data/smm-pools';

const Wrapper = styled(FlexCol)`
	padding: 10px;
`;

const Item = styled(FlexRow)<{ isSeleted: boolean }>`
	align-items: center;
	padding: 0 10px 0 0;
	background-color: ${({ isSeleted }) =>
		isSeleted ? 'rgb(26, 26, 51)' : 'rgb(12,12,34)'};
	border: 1px solid ${({ isSeleted }) => (isSeleted ? '#fff' : '#235ee1')};
	border-radius: 6px;
	box-shadow: 0 4px 4px #000000;
	margin: 5px;
	overflow: hidden;

	:hover {
		cursor: pointer;
		background-color: rgb(34, 34, 64);
	}
`;

const ItemWrapper = styled(FlexRow)`
	flex-wrap: wrap;
`;

type SelectAnExchangeProps = {
	selectedValue: number;
	setSelectedValue: Dispatch<SetStateAction<number>>;
};

export const SelectAnExchange: React.ComponentType<SelectAnExchangeProps> = ({
	selectedValue,
	setSelectedValue,
}) => {
	return (
		<Wrapper>
			<Typography
				variant={'h6'}
				color={'#719DEC'}
				style={{ alignSelf: 'flex-start' }}
			>
				Select an Exchange
			</Typography>
			<Gutter spacing={0.5} />
			<ItemWrapper>
				{Exchanges.map((d, i) => (
					<Item
						key={d.name}
						isSeleted={selectedValue === i}
						onClick={() => setSelectedValue(i)}
					>
						<img src={d.icon} width={45} />
						<Gutter gap={0.6} />
						<Typography
							variant={'body1'}
							color={selectedValue === i ? '#fff' : '#aaa'}
							fontWeight={600}
						>
							{d.name}
						</Typography>
					</Item>
				))}
			</ItemWrapper>
		</Wrapper>
	);
};
