import React from 'react';
import './App.css';
import { store } from './store';
import { Provider as StateProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import AppRoutes from './screen/routes';
import { SnackBarStackWrapper } from './components/atoms/Snackbar';

function App() {
	return (
		<StateProvider store={store}>
			<ThemeProvider theme={theme}>
				<SnackBarStackWrapper>
					<AppRoutes />
				</SnackBarStackWrapper>
			</ThemeProvider>
		</StateProvider>
	);
}

export default App;
