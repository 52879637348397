import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { restoreState } from 'store/auth/actions';
import { type RootState } from 'store/index-reducer';
import { PrivateRoute } from './PrivateRoutes';
import { LandingPage } from './Loading/LandingPage';
import { Dashboard } from './Dashboard/Dashboard';
import { Layout } from './Layout';

function AppRoutes() {
	const dispatch = useDispatch();
	const isRestoringState = useSelector(
		(state: RootState) => state.auth.isRestoring,
	);

	if (isRestoringState) {
		dispatch(restoreState());
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route
					element={
						<Layout wrapperStyle={{ background: 'rgb(0,2,37)' }}>
							<PrivateRoute isRestoringState={isRestoringState} />
						</Layout>
					}
					caseSensitive={false}
				>
					<Route path={'/'} element={<Dashboard />} />
				</Route>
				<Route path={'/login'} element={<LandingPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default AppRoutes;
