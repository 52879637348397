import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { RootState } from 'store/index-reducer';

type PrivateRoutesProps = {
	isRestoringState: boolean;
};

export const PrivateRoute: React.ComponentType<PrivateRoutesProps> = ({
	isRestoringState,
}) => {
	const { user } = useSelector((state: RootState) => state.user);

	if (isRestoringState) {
		return <></>;
	}

	return user?.token ? <Outlet /> : <Navigate to={'/login'} />;
};
