import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DepositPanel } from './DepositPanel';
import { WithdrawalPanel } from './WithdrawalPanel';

const StyledContainer = styled(Box)`
	padding: 20px 16px;
	border-radius: 12px;
	width: 100%;
	background-color: #00001f;
`;

const TabGroup = styled(Box)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 8px;
	padding: 6px;
	background-color: #33334a;
	border-radius: 4px;
`;

const TabButton = styled(Box)<{ selected?: boolean }>`
	flex: 1;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.selected ? '#00001f' : 'transparent'};
	border-radius: 4px;
`;

const TabText = styled(Typography)`
	font-size: 18px;
	font-weight: 500;
	margin: 12px 8px;
`;

enum PanelTypes {
	Deposit = 'deposit',
	Withdraw = 'withdraw',
}

const DepositWithdrawPanel: React.ComponentType = () => {
	const [loading, setLoading] = useState(false);
	const [tabs, setTabs] = useState('deposit');

	const [selectedPanel, setSelectedPanel] = useState<PanelTypes>(
		PanelTypes.Deposit,
	);

	const activePanel = useMemo(() => {
		return selectedPanel === PanelTypes.Deposit ? (
			<DepositPanel />
		) : (
			<WithdrawalPanel />
		);
	}, [selectedPanel]);

	const switchPanel = useCallback((panel: PanelTypes) => {
		if (loading) return;

		setSelectedPanel(panel);
	}, []);

	return (
		<Box display="flex" alignItems="flex-start">
			<StyledContainer>
				<TabGroup>
					<TabButton
						selected={selectedPanel === PanelTypes.Deposit}
						onClick={() => switchPanel(PanelTypes.Deposit)}
					>
						<TabText>Deposit</TabText>
					</TabButton>

					<TabButton
						selected={selectedPanel === PanelTypes.Withdraw}
						onClick={() => switchPanel(PanelTypes.Withdraw)}
					>
						<TabText>Withdrawal</TabText>
					</TabButton>
				</TabGroup>

				<Box height={16} />

				{activePanel}
			</StyledContainer>
		</Box>
	);
};

export { DepositWithdrawPanel };
