import React, { useEffect, useState } from 'react';
import { FlexCol, FlexRow } from 'components/atoms/Flex';
import QRCode from 'react-qr-code';
import { IUser } from 'typings';
import { getUserBalance } from 'api/auth';
import { Gutter } from 'components/atoms/Gutter';
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
} from '@mui/material';
import { pascalCase } from '../../helpers/string';
import { DepositWithdrawPanel } from './DepositWithdrawPanel';

const Wrapper = styled(FlexCol)`
	min-width: 300px;
	border: 1px solid #235ee1;
	border-radius: 8px;
	min-height: 500px;
	max-height: 510px;
`;

export const Panel = () => {
	return (
		<Wrapper>
			<DepositWithdrawPanel />
		</Wrapper>
	);
};
