import type {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';
import type { Store } from 'redux';
import type { IAPIErrorResponse } from 'typings/standard';
import type { RootState } from 'store/index-reducer';

export default (axiosClient: AxiosInstance, store: Store) => {
	axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
		const state: RootState = store.getState();
		const { user } = state.user;

		if (user?.token) {
			if (!config.headers) {
				config.headers = {};
			}

			config.headers.Authorization = `Bearer ${user?.token}`;
		}

		return config;
	});

	axiosClient.interceptors.response.use(
		(response: AxiosResponse<any>) => response,
		(error: AxiosError) => {
			if (error?.message === 'Network Error') {
				const networkError: IAPIErrorResponse = {
					errors: [
						{ message: 'Please check your internet connection' },
					],
				};

				return Promise.reject({
					response: {
						data: networkError,
					},
				});
			}

			return Promise.reject(error);
		},
	);
};
