import { styled } from '@mui/system';
import { FlexCol } from 'components/atoms/Flex';
import { PoolSelection } from 'screen/Dashboard/chain';
import { SelectAnExchange } from 'screen/Dashboard/SelectAnExchange';
import { useState } from 'react';
import { Exchanges } from 'data/smm-pools';
import { Gutter } from 'components/atoms/Gutter';
import { Panel } from 'screen/Dashboard/Panel';

const Wrapper = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	background-color: rgb(0, 2, 37);
	max-width: 1200px;
	height: 100%;

	@media (max-width: 1000px) {
		align-items: center;
		grid-template-columns: 1fr;
	}
`;

const Container = styled(FlexCol)`
	width: 100%;
	height: 80vh;
	background-color: rgb(0, 2, 37);
`;

const Col = styled(FlexCol)`
	align-items: flex-start;
	justify-content: center;
	height: 100%;

	@media (max-width: 1000px) {
		align-items: center;
	}
`;

const Box = styled(Col)`
	@media (max-width: 1000px) {
		display: none;
	}
`;

export const Dashboard = () => {
	const [seletedValue, setSeletedValue] = useState(0);

	return (
		<Container>
			<Wrapper>
				<Col>
					<SelectAnExchange
						selectedValue={seletedValue}
						setSelectedValue={setSeletedValue}
					/>
					<Gutter spacing={3} />
					<PoolSelection dataSource={Exchanges[seletedValue].value} />
				</Col>
				<Box>
					<Panel />
				</Box>
			</Wrapper>
		</Container>
	);
};
