import React, { useCallback } from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Brand from 'assets/brand.svg';
import { SMMPool } from 'data/smm-pools';
import { FlexRow } from 'components/atoms/Flex';
import { Gutter } from 'components/atoms/Gutter';

const Image = styled.img`
	height: 180px;
	width: 100%;
	object-fit: contain;
`;

const StyledCard = styled(Card)`
	position: relative;
	max-width: 300px;
	min-width: 250px;
	background: #1a1a33;
	border: 1px solid #235ee1;
	box-shadow: 0 4px 4px #000000;
	border-radius: 12px;

	:hover {
		opacity: 0.8;
	}
`;

const Tag = styled.div`
	padding: 4px 8px;
	border-radius: 4px;
`;

const TagWrapper = styled(FlexRow)`
	position: absolute;
	top: 10px;
	left: 10px;
`;

export const PoolItem = ({
	illustration,
	backgroundColor,
	name,
	tags,
}: SMMPool) => {
	const RenderTag = useCallback(
		({ color, name: P }: { name: string; color: string }) => {
			return (
				<Tag style={{ background: color }}>
					<Typography variant={'caption'} color={'#000'}>
						{P}
					</Typography>
				</Tag>
			);
		},
		[],
	);

	return (
		<StyledCard>
			<CardActionArea>
				<Image
					src={illustration}
					alt="pool"
					style={{ background: backgroundColor }}
				/>
				<TagWrapper>
					{tags.map((t) => (
						<RenderTag key={`${t.name}-${t.color}`} {...t} />
					))}
				</TagWrapper>
				<CardContent>
					<Typography
						gutterBottom
						variant="h6"
						component="div"
						color={'#fff'}
						fontWeight={500}
					>
						{name}
					</Typography>
					<img src={Brand} height={20} alt={'brand'} />
					<Gutter />
				</CardContent>
			</CardActionArea>
		</StyledCard>
	);
};
