import React, { useState } from 'react';
import Logo from 'assets/vcred_blue.svg';
import Brand from 'assets/brand-logo.svg';
import { Box } from '@mui/system';
import {
	Avatar,
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index-reducer';
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import { FlexCol, FlexRow } from './Flex';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Gutter } from './Gutter';
import { useNavigate } from 'react-router-dom';
import { setLogout } from 'store/user/actions';
import { WalletModal } from 'screen/Dashboard/WalletModal';

const Wrapper = styled('div')`
	position: sticky;
	top: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	//padding: 20px;
	//width: 100%;
	background-color: rgb(26, 26, 51);
	z-index: 2;
	padding: 10px;

	@media (max-width: 600px) {
		box-shadow: 0 1px 1px rgba(0, 0, 31, 0.1);
	}
`;

const Image = styled('img')``;

const Bar = styled('div')`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 44px;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
`;

const Dropdown = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`;

const StyledRow = styled(FlexRow)`
	min-width: 220px;
	padding: 16px;
	border: 1px solid #eee;
	border-radius: 8px;

	:hover {
		background-color: #f1f1f1;
	}
`;

const ConnectButton = styled(StyledRow)`
	min-width: 20px;
	display: none;

	@media (max-width: 1000px) {
		display: flex;
	}
`;

const StyledButton = styled(Button)`
	padding: 0;
	margin: 0;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	min-width: 40px;
`;

export const Navbar: React.ComponentType<{ isDark?: boolean }> = ({
	isDark,
}) => {
	const [open, setIsModalOpen] = useState(false);
	const { user } = useSelector((state: RootState) => state.user);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<>
			<Wrapper
				style={{
					background: isDark ? 'rgb(26, 26, 51)' : 'rgb(245,245,245)',
				}}
			>
				<Bar>
					<a href="/">
						<Image
							src={isDark ? Brand : Logo}
							alt={'vcred'}
							width={100}
						/>
					</a>
					<Row>
						<Box width={20} />
					</Row>
					<FlexRow>
						<ConnectButton
							onClick={() => {
								setIsModalOpen(true);
							}}
						>
							<Typography color={'#fff'} variant={'subtitle2'}>
								Wallet
							</Typography>
						</ConnectButton>
						<Box width={20} />
						{user?.profilePic && (
							<PopupState
								variant="popper"
								popupId="demo-popup-popper"
								disableAutoFocus={true}
							>
								{(popupState) => (
									<ClickAwayListener
										onClickAway={() => popupState.close()}
									>
										<div>
											<StyledButton
												{...bindToggle(popupState)}
											>
												<Avatar
													src={user?.profilePic}
												/>
											</StyledButton>
											<Popper
												{...bindPopper(popupState)}
												transition
												placement="bottom-start"
											>
												{({ TransitionProps }) => (
													<Fade
														{...TransitionProps}
														timeout={350}
													>
														<Dropdown>
															<StyledRow
																onClick={() => {
																	localStorage.removeItem(
																		'user',
																	);
																	dispatch(
																		setLogout(),
																	);
																	navigate(
																		'/login',
																	);
																}}
															>
																<ExitToAppIcon />
																<Gutter
																	gap={0.5}
																/>
																<Typography
																	variant={
																		'subtitle2'
																	}
																	color={
																		'#000'
																	}
																>
																	Logout
																</Typography>
															</StyledRow>
														</Dropdown>
													</Fade>
												)}
											</Popper>
										</div>
									</ClickAwayListener>
								)}
							</PopupState>
						)}
					</FlexRow>
				</Bar>
			</Wrapper>
			<WalletModal open={open} onClose={() => setIsModalOpen(false)} />
		</>
	);
};
