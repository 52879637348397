import styled from '@emotion/styled';

interface IFlexProps {
	justify?: string;
	align?: string;
	flexWrap?: string;
}
const FlexRow = styled.div<IFlexProps>`
	display: flex;
	flex-direction: row;
	justify-content: ${(props) => props.justify || 'flex-start'};
	align-items: ${(props) => props.align || 'center'};
	flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
`;

const FlexCol = styled(FlexRow)<IFlexProps>`
	flex-direction: column;
	flex-wrap: ${(props) => props.flexWrap};
	align-items: ${(props) => props.align};
`;

export { FlexRow, FlexCol };
