import React, { CSSProperties } from 'react';
import { styled } from '@mui/system';
import { Navbar } from 'components/atoms/Navbar';

const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	background-color: ${({ theme: { palette } }) => palette.background.default};
`;

const Box = styled('div')`
	width: 100%;
	height: 100%;
`;

const Layout: React.ComponentType<{
	children?: React.ReactNode;
	wrapperStyle?: CSSProperties;
}> = ({ children, wrapperStyle }) => {
	return (
		<Wrapper style={wrapperStyle}>
			<Navbar isDark={!!wrapperStyle} />
			<Box>{children}</Box>
		</Wrapper>
	);
};

export { Layout };
