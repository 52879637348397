import Candle from 'assets/candles.svg';
import AiEngine from 'assets/ai_engine.svg';
import Vertex from 'assets/vertex.jpeg';
import Hubble from 'assets/hubble.jpeg';
import Dexalot from 'assets/dexalot.png';
import Dydx from 'assets/dydx.jpeg';

export type SMMPool = {
	id: string;
	name: string;
	illustration: string;
	description: string;

	riskDesc: string;
	volatilityDesc: string;
	rewardDesc: string;
	backgroundColor: string;
	tags: {
		name: string;
		color: string;
	}[];
	exchanges: { name: string; icon: string }[];
	targetAssetCategory: {
		name: string;
		chainType: string;
		coinIcon: string;
	};
	tokens: any[];
};

const sMMPools: SMMPool[] = [
	{
		id: 'long-short-agent',
		name: 'Perp Agent',
		illustration: Candle,
		backgroundColor: 'linear-gradient(180deg, #F5C089 0%, #F4B372 100%)',

		description:
			'This agent executes long/short using a set of strategies which combine momentum and volatility. During each accounting period, the agent uses a fraction of the pool allocated to open short/long positions on the target asset at each epoch based on the strategy and parameters set by the AI engine for the given market conditions. Exchanges used: Binance & dydx',

		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		tags: [
			{
				name: 'Avalanche ',
				color: '#FF7D00',
			},
		],
		exchanges: [
			{
				name: 'dydx',
				icon: '/crypto/dydx.svg',
			},
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [],
	},
	{
		id: 'ai-agent',
		name: 'Spread Agent',
		illustration: AiEngine,
		backgroundColor: 'linear-gradient(180deg, #A2E2E8 0%, #91DDE4 100%)',
		description:
			'This agent buys/sells target asset using a set of strategies which combine momentum and volatility. During each accounting period, the agent uses a fraction of the pool allocated to continuously buy/sell the target asset based on the strategy and parameters set by the AI engine for the given market conditions. Exchange used: Binance',

		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		tags: [
			{
				name: 'Avalanche',
				color: '#1CCAD5',
			},
		],
		exchanges: [
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [],
	},
];

export const Exchanges = [
	{ name: 'DYDX', value: sMMPools, icon: Dydx },
	{ name: 'Vertex', value: [sMMPools[0]], icon: Vertex },
	{ name: 'Hubble', value: [sMMPools[0]], icon: Hubble },
	{ name: 'Dexalot', value: [sMMPools[1]], icon: Dexalot },
];

export { sMMPools };
