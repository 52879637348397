import { combineReducers } from 'redux';

import auth from 'store/auth';
import user from './user';

const rootReducer = combineReducers({
	auth,
	user,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
