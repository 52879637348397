import React from 'react';
import styled from '@emotion/styled';
import { LAYOUT_WIDTH } from 'data/constants';
import { FlexCol, FlexRow } from 'components/atoms/Flex';
import { Button, Typography } from '@mui/material';
import HeroImage from 'assets/hero.svg';
import { Gutter } from 'components/atoms/Gutter';
import { signInWithPopup, TwitterAuthProvider } from 'firebase/auth';
import { authentication } from '../../firebaseConfig';
import { TwitterLoginButton } from 'react-social-login-buttons';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from '../../store/user/actions';
import { RootState } from '../../store/index-reducer';
import { useNavigate } from 'react-router-dom';
import { postTwitter } from '../../api/auth';
import { IUser } from '../../typings';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	min-height: 80vh;
	padding: 20px 20px 100px 20px;
`;

const Wrapper = styled('div')`
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1.5fr;
	row-gap: 20px;
	width: 100%;
	max-width: ${() => LAYOUT_WIDTH}px;

	@media (max-width: 1000px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

const ImageWrapper = styled.div`
	position: relative;
	width: 100%;
	//height: 100%;
	min-height: 500px;

	@media (max-width: 1000px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@media (max-width: 650px) {
		min-height: 300px;
		align-self: center;
	}
`;

const Image = styled('img')`
	@media (max-width: 650px) {
		width: 400px;
	}
	@media (max-width: 450px) {
		width: 300px;
	}
`;

const Col = styled(FlexCol)`
	@media (max-width: 1000px) {
		align-items: center;
	}
`;

const VerticalGap = styled.div`
	height: 64px;

	@media (max-width: 600px) {
		height: 32px;
	}
`;

const Btn = styled(Button)`
	padding: 12px 30px;
	border-radius: 7px;
	font-weight: 600;
`;

type TwitterResponse = {
	displayName: string | null;
	email: string | null;
	phoneNumber: string | null;
	photoURL: string | null;
	providerId: string | null;
	uid: string | null;
};

const LaunchButton = () => {
	const { user } = useSelector((state: RootState) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSuccess = (user: IUser) => {
		localStorage.setItem('user', JSON.stringify(user));
		dispatch(setCurrentUser(user));
	};

	const signInWithTwitter = () => {
		const provider = new TwitterAuthProvider();
		signInWithPopup(authentication, provider)
			.then(async (res) => {
				const data: TwitterResponse = res.user.providerData[0];

				const res2 = await postTwitter({
					name: data.displayName || '',
					profilePic: data.photoURL || '',
					email: data.email || 'avinash2050kumar@gmail.com',
				});

				onSuccess(res2);
			})
			.catch(() => {
				//
			});
	};

	return (
		<FlexRow>
			{!user?.token ? (
				<TwitterLoginButton onClick={signInWithTwitter} />
			) : (
				<Btn variant="outlined" onClick={() => navigate('/')}>
					🚀 Launch Dashboard
				</Btn>
			)}
		</FlexRow>
	);
};

export const Hero = () => {
	return (
		<Container>
			<VerticalGap />
			<Wrapper>
				<Col align={'flex-start'}>
					<Typography
						variant={'h3'}
						fontWeight={500}
						color={'#002581'}
						style={{ fontSize: '40px', textAlign: 'center' }}
					>
						Smarter Market Making
					</Typography>
					<Gutter spacing={2} />

					<LaunchButton />
				</Col>
				<ImageWrapper>
					<Image src={HeroImage} alt={'vcred'} />
				</ImageWrapper>
			</Wrapper>
			<Gutter spacing={2} />
		</Container>
	);
};
