import React, {
	type ChangeEvent,
	type CSSProperties,
	useCallback,
} from 'react';
import { useField } from 'formik';

import { FlexCol, FlexRow } from 'components/atoms/Flex';
import {
	OutlinedInput,
	TextFieldProps,
	TextField,
	OutlinedInputProps,
} from '@mui/material';
import { ErrorText } from '../atoms/ErrorText';
import { Gutter } from '../atoms/Gutter';

const inputStyles = {
	'&, &:hover': {
		outline: '1px solid #235EE1',
	},
	color: 'white',
	fontWeight: 500,
	'& .MuiOutlinedInput-root': {
		fontWeight: 500,
	},
	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		display: 'none',
	},
};

export type TextInputProps = Omit<OutlinedInputProps, 'label'> & {
	title?: string;
	name: string;
	placeholder?: string;
	wrapperStyle?: CSSProperties;
	nextInputRef?: React.MutableRefObject<typeof TextField> | null;
};

export const FieldTextInput: React.ComponentType<TextInputProps> = ({
	name,
	onChange,
	wrapperStyle,
	nextInputRef,
	...props
}) => {
	const [field, meta, helpers] = useField(name);

	const error = meta.touched && meta.error;

	const handleBlur = useCallback(() => {
		helpers.setValue(meta.value?.trim());
		helpers.setTouched(true);
	}, [helpers, meta.value]);

	return (
		<FlexCol align={'flex-start'} style={wrapperStyle}>
			<OutlinedInput
				disabled={false}
				placeholder="0.00"
				value={field.value ? field.value.toString() : ''}
				onChange={(e) => {
					helpers.setValue(e.target.value);
					onChange?.(e);
				}}
				fullWidth
				margin="dense"
				sx={inputStyles}
				{...props}
			/>
			<FlexCol>
				<Gutter spacing={0.4} />
				<FlexRow style={{ width: '100%' }} align={'flex-start'}>
					<ErrorText text={error} />
				</FlexRow>
			</FlexCol>
		</FlexCol>
	);
};
