import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { PoolItem } from './pool-item';
import { SMMPool } from 'data/smm-pools';
import { FlexCol } from 'components/atoms/Flex';
import { Gutter } from 'components/atoms/Gutter';

const Container = styled.div`
	display: flex;
	padding: 10px;
	justify-content: center;
	align-items: center;
`;

const GridRow = styled.div`
	display: grid;
	max-width: 100%;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	overflow: scroll;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;

	@media (max-width: 850px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
	}
`;

const Col = styled(FlexCol)`
	max-width: 100%;
	align-items: center;
`;

const PoolSelection: React.ComponentType<{ dataSource: SMMPool[] }> = ({
	dataSource,
}) => {
	const RenderPool = useCallback(({ ...pool }: SMMPool) => {
		return <PoolItem {...pool} />;
	}, []);

	return (
		<Container>
			<Col>
				<Typography
					variant={'h6'}
					color={'#719DEC'}
					style={{ alignSelf: 'flex-start' }}
				>
					Select an agent
				</Typography>
				<Gutter />
				<GridRow>
					{dataSource.map((pool: SMMPool) => (
						<RenderPool key={pool.id} {...pool} />
					))}
				</GridRow>
			</Col>
		</Container>
	);
};

export { PoolSelection };
