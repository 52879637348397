import { axios } from 'store';
import { IUser } from 'typings';

type TwitterBody = {
	email: string;
	name: string;
	profilePic: string;
};

export const postTwitter = async (payload: TwitterBody) => {
	const url = '/api/auth/twitter';

	try {
		const { data } = await axios.post<IUser>(url, payload);

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const getUserBalance = async (
	address: string,
	chain: 'AVAX' | 'ETH',
) => {
	const url = `/api/user/balance?address=${address}&chain=${chain}`;

	try {
		const { data } = await axios.get<{ balance: string; symbol: string }>(
			url,
		);

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const withdrawalBalance = async (payload: {
	chain: 'AVAX' | 'ETH';
	destination: string;
	amount: string;
}) => {
	const url = '/api/user/withdraw';

	try {
		const { data } = await axios.post<IUser>(url, payload);

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const getCurrentUser = async (token: string) => {
	const url = '/api/auth/current-user';

	try {
		const { data } = await axios.get<IUser>(url, {
			headers: { Authorization: `Bearer ${token}` },
		});

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};
