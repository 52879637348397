import React from 'react';
import { styled } from '@mui/system';
import { Hero } from './Hero';
import { Layout } from '../Layout';

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: max-content;
`;

export const LandingPage = () => {
	return (
		<Layout>
			<Container>
				<Hero />
			</Container>
		</Layout>
	);
};
