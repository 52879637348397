import React from 'react';
import type { TypographyProps } from '@mui/material';
import { Typography, useTheme } from '@mui/material';

type LabelProps = TypographyProps;

export const Label: React.ComponentType<LabelProps> = ({
	children,
	...rest
}) => {
	return (
		<Typography
			color={'#fff'}
			fontWeight={500}
			variant={'subtitle2'}
			{...rest}
		>
			{children}
		</Typography>
	);
};
