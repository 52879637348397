import React, { useEffect, useState } from 'react';
import { Button, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AvailabeExchange, exchanges } from 'data/exchanges';
import { FlexCol, FlexRow } from 'components/atoms/Flex';
import { Gutter } from 'components/atoms/Gutter';
import { Label } from 'components/atoms/Label';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FieldTextInput } from 'components/molecule/FieldTextInput';
import { getUserBalance, withdrawalBalance } from 'api/auth';
import { IUser } from 'typings';
import { useAppErrors } from 'hooks/useAppErrors';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index-reducer';

const ItemWrapper = styled(FlexRow)`
	flex-wrap: wrap;
`;

const Item = styled(FlexRow)<{ isSeleted: boolean }>`
	align-items: center;
	padding: 10px 15px;
	background-color: ${({ isSeleted }) =>
		isSeleted ? 'rgb(26, 26, 51)' : 'rgb(12,12,34)'};
	border: 1px solid ${({ isSeleted }) => (isSeleted ? '#fff' : '#235ee1')};
	border-radius: 6px;
	box-shadow: 0 4px 4px #000000;
	margin: 5px;
	overflow: hidden;

	:hover {
		cursor: pointer;
		background-color: rgb(34, 34, 64);
	}
`;

const StyledButton = styled(Button)`
	padding: 16px;
	width: 100%;
	background-color: #235ee1;
	color: #fff;

	:disabled {
		color: #aaa;
		background-color: #083598;
		cursor: no-drop;
	}
`;

const Wrapper = styled(FlexCol)`
	padding: 16px;
	min-width: 300px;
	border-radius: 8px;
`;

const validationSchema = Yup.object().shape({
	destination: Yup.string().required('Required'),
	amount: Yup.string().required('Required'),
});

export const WithdrawalPanel = () => {
	const user: IUser = useSelector(
		(state: RootState) => state.user.user,
	) as IUser;
	const [balance, setBalance] = useState({ balance: '', symbol: '' });
	const [exchange, setExchange] = useState<AvailabeExchange>('AVAX');
	const { setAppError } = useAppErrors();
	const { enqueueSnackbar } = useSnackbar();

	const fetchBalance = async () => {
		try {
			const res = await getUserBalance(user.address, exchange);
			setBalance({ ...res });
		} catch (e) {}
	};

	useEffect(() => {
		fetchBalance();
	}, [exchange]);

	const onSubmit = async (values: any, actions: any) => {
		try {
			actions.setSubmitting(true);
			if (values.amount <= balance.balance) {
				await withdrawalBalance(values);

				enqueueSnackbar('Successfully withdrawal', {
					variant: 'success',
				});
				actions.resetForm({
					value: {
						chain: values.chain,
						destination: '',
						amount: '0',
					},
				});
			} else {
				actions.setFieldError('amount', 'Insufficient fund');
			}
		} catch (e: any) {
			setAppError(e);
		} finally {
			actions.setSubmitting(false);
		}
	};

	return (
		<Wrapper>
			<Formik
				initialValues={{
					chain: 'AVAX',
					destination: '',
					amount: '0',
				}}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				{({
					setFieldValue,
					handleSubmit,
					isSubmitting,
					dirty,
					values,
				}) => (
					<>
						<ItemWrapper>
							{exchanges.map((d) => (
								<Item
									key={d.name}
									isSeleted={exchange === d.name}
									onClick={() => {
										setFieldValue('chain', d.name);
										setExchange(d.name);
									}}
								>
									<Typography
										variant={'body1'}
										color={
											exchange === d.name
												? '#fff'
												: '#aaa'
										}
										fontWeight={600}
									>
										{d.name}
									</Typography>
								</Item>
							))}
						</ItemWrapper>
						<Gutter />
						<FlexCol align={'flex-start'}>
							<FlexRow
								justify={'space-between'}
								style={{ width: '100%', flexWrap: 'nowrap' }}
							>
								<Label>Amount*</Label>
								<Label>
									{`${balance.balance} ${balance.symbol}`}
								</Label>
							</FlexRow>
							<Gutter spacing={0.2} />
							<FieldTextInput
								name={'amount'}
								type="number"
								endAdornment={
									<Box>
										<Button
											onClick={() =>
												setFieldValue(
													'amount',
													balance.balance,
												)
											}
										>
											<Typography
												fontSize={12}
												fontWeight={500}
											>
												MAX
											</Typography>
										</Button>
									</Box>
								}
								wrapperStyle={{ width: '100%' }}
							/>
							<Gutter spacing={0.2} />

							<Gutter spacing={1} />
							<Label>Address*</Label>
							<Gutter spacing={0.2} />
							<FieldTextInput
								name={'destination'}
								wrapperStyle={{ width: '100%' }}
							/>
							<Gutter spacing={2} />
							<StyledButton
								variant="contained"
								disabled={
									isSubmitting ||
									!dirty ||
									values.amount === '0'
								}
								onClick={() => handleSubmit()}
							>
								Withdrawal
							</StyledButton>
						</FlexCol>
					</>
				)}
			</Formik>
		</Wrapper>
	);
};
