import {
	FETCH_CURRENT_USER,
	HYDRATE_SESSION,
	LOGOUT,
	SET_CURRENT_BUSINESS,
	SET_CURRENT_USER,
	SET_TOKEN,
	type UserActionsTypes,
} from 'store/user/types';
import type { IUser } from 'typings/auth';

export const setSession = (user: IUser): UserActionsTypes => ({
	type: HYDRATE_SESSION,
	user: user,
});

export const setToken = (token: string): UserActionsTypes => ({
	type: SET_TOKEN,
	payload: token,
});

export const setCurrentUser = (user: IUser): UserActionsTypes => ({
	type: SET_CURRENT_USER,
	payload: user,
});

export const fetchCurrentUser = (): UserActionsTypes => ({
	type: FETCH_CURRENT_USER,
});

export const setCurrentBusiness = (payload: string): UserActionsTypes => ({
	type: SET_CURRENT_BUSINESS,
	payload,
});

export const setLogout = (): UserActionsTypes => ({
	type: LOGOUT,
});
