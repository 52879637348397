import { call, fork, put, take } from 'redux-saga/effects';
import { HYDRATE_SESSION } from 'store/user/types';
import { RESTORE_STATE } from './types';
import { restoreStateDone } from 'store/auth/actions';
import { IUser } from 'typings';
import { getCurrentUser } from 'api/auth';
import { setCurrentUser, setLogout } from '../user/actions';

function* restoreAppState() {
	try {
		const user: string = yield call(
			async () => await localStorage.getItem('user'),
		);

		if (user) {
			const parsedUser: IUser = JSON.parse(user);
			const currentUser: IUser = yield call(
				async () => await getCurrentUser(parsedUser?.token),
			);

			yield put(
				setCurrentUser({ ...currentUser, token: parsedUser?.token }),
			);
		}
	} catch (e) {
		yield put(setLogout());
	} finally {
		yield put(restoreStateDone());
	}
}

function* restoreAppStateWatcher() {
	yield take([RESTORE_STATE]);
	yield fork(restoreAppState);
}

// add typings later
function* authWatcher(): any {
	while (true) {
		const { type } = yield take([HYDRATE_SESSION]);

		switch (type) {
			case HYDRATE_SESSION:
				// no need to do anything else here
				break;
		}
	}
}

export default [restoreAppStateWatcher(), authWatcher()];
