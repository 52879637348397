import React from 'react';
import { Modal, ModalProps } from '@mui/material';
import { Panel } from 'screen/Dashboard/Panel';
import { Box } from '@mui/system';

type WalletModalProps = {} & Omit<ModalProps, 'children'>;

export const WalletModal: React.ComponentType<WalletModalProps> = ({
	open,
	onClose,
	...props
}) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			{...props}
		>
			<Box
				sx={{
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'rgb(0, 2, 37)',
				}}
			>
				<Panel />
			</Box>
		</Modal>
	);
};
