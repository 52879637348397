import produce from 'immer';
import { IUser } from 'typings/auth';

import {
	HYDRATE_SESSION,
	LOGOUT,
	SET_CURRENT_USER,
	type UserActionsTypes,
} from 'store/user/types';

type UserState = { user?: IUser };

const initialState: UserState = {
	user: undefined,
};

const reducer = produce((draft: UserState, action: UserActionsTypes) => {
	switch (action.type) {
		case HYDRATE_SESSION:
			draft.user = action.user;
			return;

		case LOGOUT:
			draft.user = undefined;
			return;

		case SET_CURRENT_USER:
			draft.user = action.payload;
			return;

		default:
			return;
	}
}, initialState);

export default reducer;
