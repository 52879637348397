import React from 'react';
import { styled } from '@mui/system';
import type { ColorsType } from 'typings/color';
import { SNACKBAR_OPTIONS } from 'data/snackbar';
import { Gutter } from 'components/atoms/Gutter';
import { Typography } from '@mui/material';
import { FlexRow } from 'components/atoms/Flex';
import type { CustomContentProps } from 'notistack';
import { COLOR } from '../../../data/color';

const Wrapper = styled(FlexRow, {
	shouldForwardProp: (props) => props !== 'color',
})<{ color: ColorsType }>`
	background-color: ${({ color }) => COLOR[color]};
	padding: 10px;
	padding-left: 20px;
	border-radius: 25px;
	color: #fff;
	align-items: center;
	justify-content: flex-start;

	:hover {
		cursor: pointer;
	}
`;

const Btn = styled(Wrapper)`
	padding: 5px 10px;
	background-color: ${({ theme }) => theme.palette.background.default};
`;

export const Snackbar = React.forwardRef<HTMLDivElement, CustomContentProps>(
	({ variant, message }, ref) => {
		const color = SNACKBAR_OPTIONS[variant].color;
		return (
			<Wrapper ref={ref} color={color}>
				<Typography variant={'subtitle2'} fontWeight={600}>
					{message}
				</Typography>
				<Gutter gap={2} />
			</Wrapper>
		);
	},
);

Snackbar.displayName = 'Snackbar';
